import React from 'react'
import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import IconsSwitch from 'src/components/IconsSwitch/_index'

type Circle = {
  circleColor: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 643px;
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 240px;
  }

  @media ${device.desktopLG} {
    min-height: 275px;
  }
`
const Circle = styled.div<Circle>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${(props: Circle) => props.circleColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const ErrorView = () => (
  <Container>
    <div className='text-center'>
      <Circle className='mx-auto' circleColor='#F56A50'>
        <IconsSwitch customPath='' icon='status/ic_close_circle' lib='interco' width='24' height='24' color='#fff' />
      </Circle>
      <h3 className='font-citrina fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-2 mt-4 text-grayscale--500'>
        Erro ao realizar a busca
      </h3>
      <p className='fs-14 fs-lg-18 lh-16 lh-lg-18 text-grayscale--500'>
        Por favor, tente novamente em alguns minutos.
      </p>
    </div>
  </Container>
)

export default ErrorView
