import React, { useEffect } from 'react'
import styled from 'styled-components'
import { PaginationProps } from '../_types'
import { Button } from '@interco/inter-ui/components/Button'
import { Theme, initTheme } from '@interco/inter-ui'
import { orange } from 'src/styles/colors'
import IconsSwitch from 'src/components/IconsSwitch/_index'

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    width: 50px;
    margin: 0 8px;
    padding: 5px 10px;
    border-radius: 5px;
    svg {
      margin: 0 auto;
    }
  }
`

const PaginationDisplay = styled.div`
  max-width: 90px;
  padding: 0 8px;
  flex-wrap: wrap;
  height: 34px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  text-align: center;

  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${orange.extra};
  cursor: default;

  * {
    align-self: center;
  }
`

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
}: PaginationProps) => {
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const handlePageChange = (page: number) => {
    if (page >= 0 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const lastPage = totalPages - 1

  return (
    <PaginatorWrapper>
      <Button
        icon={<IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_first_page' width='20' height='20' color='#fff' />}
        onClick={() => handlePageChange(0)}
        disabled={currentPage === 0}
        aria-label='Ir para primeira página'
        title='Ir para primeira página'
      />
      <Button
        icon={<IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_chevron_left' width='20' height='20' color='#fff' />}
        onClick={() => handlePageChange(Math.max(0, currentPage - 1))}
        disabled={currentPage === 0}
        aria-label='Ir para página anterior'
        title='Ir para página anterior'
      />

      <PaginationDisplay
        aria-live='polite'
        aria-label={`Mostrando página ${currentPage} de ${lastPage}`}
      >
        <strong>{currentPage}</strong>
        {!isLoading && (
          <>
            <span>/</span>
            <span>{lastPage}</span>
          </>
        )}
      </PaginationDisplay>
      <Button
        icon={
          !isLoading && <IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_chevron_right' width='20' height='20' color='#fff' />
        }
        onClick={() => handlePageChange(Math.min(currentPage + 1, lastPage))}
        aria-label='Ir para próxima página'
        title='Ir para próxima página'
        disabled={currentPage === lastPage}
        isLoading={isLoading}
      />

      <Button
        icon={!isLoading && <IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_last_page' width='20' height='20' color='#fff' />}
        disabled={currentPage === lastPage}
        onClick={() => handlePageChange(lastPage)}
        aria-label='Ir para última página'
        title='Ir para última página'
        isLoading={isLoading}
      />
    </PaginatorWrapper>
  )
}
